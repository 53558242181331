/*
 * Copyright (C) 2018-2024 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import { type CSSObject } from "@emotion/css"
import React from "react"
import { Meta } from "react-head"
import { LinkButton } from "../../components/button"
import { PrimaryLogo } from "../../components/garden-logo"
import { Heading } from "../../components/heading"
import { Activity, Build, ChevronLeft, GitHub, GitLab, Graph, Icon, type IconComponent } from "../../components/icons"
import { mqMobile } from "../../components/layout-shared"
import { Link } from "../../components/link"
import { Page } from "../../components/page"
import { IconText, Text } from "../../components/text"
import { usePageContext } from "../../contexts"
import { tokens } from "../../design-system"
import { lightTheme } from "../../design-system/light-theme"
import { ContentShell } from "../../router/layout"
import { useOauthUrl } from "../index"

const getGreatingBasedOnTime = () => {
  const now = new Date()
  const currentHour = now.getHours()
  if (currentHour < 12) {
    return "Good morning"
  } else if (currentHour < 18) {
    return "Good afternoon"
  } else {
    return "Good evening"
  }
}

export const Authentication = () => {
  const { vcsProvider } = useOauthUrl()

  usePageContext({ title: "Authentication", name: "cloud-dashboard-authentication" })

  if (!vcsProvider) {
    return null
  }

  return (
    <Page scope="none" name="free-tier-auth">
      <Meta name="viewport" content="width=device-width, initial-scale=1" />

      <div
        css={{
          margin: tokens.spacing[32],
          position: "absolute",
          top: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "calc(100% - 64px)",
          ...mqMobile({ width: "100%", margin: 0, padding: tokens.spacing[16] }),
        }}
      >
        <Link to="https://garden.io/" openInCurrentTab decorate={false}>
          <PrimaryLogo
            title="Garden"
            css={{
              width: 128,
              height: tokens.spacing[32],
              color: tokens.colors["pure-white"],
              ...mqMobile({ color: tokens.colors["text-primary"] }),
            }}
          />
        </Link>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: tokens.spacing[4],
            fontSize: 12,
          }}
        >
          <Link color="secondary" to="https://docs.garden.io/" decorate={false}>
            Documentation
          </Link>
          <div
            css={{
              color: tokens.colors["text-tertiary"],
            }}
          >
            {" | "}
          </div>
          <Link color="secondary" to="https://docs.garden.io/getting-started/quickstart" decorate={false}>
            Quickstart Guide
          </Link>
        </div>
      </div>
      <div css={{ display: "flex", minHeight: "100vh", ...mqMobile({ flexDirection: "column-reverse" }) }}>
        <div
          css={{
            flex: 1,
            backgroundColor: lightTheme.colors["anchor-secondary"],
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ...mqMobile({ flex: 1, paddingBottom: tokens.spacing[56], maxWidth: "100%" }),
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: tokens.colors["pure-white"],
              width: "500px",
              gap: tokens.spacing[40],
              justifyContent: "center",
              alignSelf: "center",
              ...mqMobile({
                width: "100%",
                padding: tokens.spacing[16],
                marginTop: tokens.spacing[16],
                position: "relative",
              }),
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: tokens.spacing[40],
                ...mqMobile({ display: "none" }),
              }}
            >
              <img alt="Blue and white flower" src="/images/flower-blue.png" css={{ width: 72, height: 72 }} />

              <Heading level={2} styles={{ color: tokens.colors["pure-white"], marginBottom: tokens.spacing[16] }}>
                Remove friction,
                <span
                  css={{
                    // using non-themed color here as this screen should always be light
                    color: lightTheme.colors["accent"],
                  }}
                >
                  {" "}
                  develop faster
                </span>
              </Heading>
            </div>

            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: tokens.spacing[40],
              }}
            >
              <AuthInfoBlock
                icon={Graph}
                heading="Create a blueprint for all your services and dependencies"
                text="Codify your workflows with the Stack Graph and spin up your entire system in a single command for
                    dev, prod, testing, and QA."
              />

              <AuthInfoBlock
                icon={Activity}
                heading="Improve observability for faster debugging"
                text="Stream logs and events in real time to see instantly what broke and what you need to do to fix it."
              />

              <AuthInfoBlock
                icon={Build}
                heading="Iterate faster with smart test and build caching"
                text=" Reduce wait times by selectively retesting and rebuilding only the parts of your stack that have
                changed."
              />
            </div>
          </div>
        </div>

        <div
          css={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: tokens.spacing[16],
            ...mqMobile({ flex: 1, minHeight: "50vh", padding: `${tokens.spacing[40]} ${tokens.spacing[16]}` }),
          }}
        >
          <Login />
        </div>
      </div>

      <img
        alt="Pink flower"
        src="/images/flower-pink-big.png"
        css={{
          position: "fixed",
          bottom: 0,
          right: 0,
          zIndex: -1,
          maxWidth: "30vw",
          marginRight: "-15vw",
          marginBottom: "-15vw",
          ...mqMobile({ display: "none" }),
        }}
      />
    </Page>
  )
}

const Login = () => {
  const { oauthUrl, vcsProvider } = useOauthUrl()

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: tokens.spacing[40],
        gap: tokens.spacing[16],
      }}
    >
      <div css={{ display: "none", ...mqMobile({ display: "block" }) }}>
        <img alt="Blue and white flower" src="/images/flower-blue.png" css={{ width: 96, height: 96 }} />
      </div>

      <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[32] }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: tokens.spacing[8],
            textAlign: "center",
          }}
        >
          <Heading level={4} color="primary">
            {getGreatingBasedOnTime()}, fellow gardener!
          </Heading>
          <Text paragraph>
            To sign in or create an account, please authenticate with your{" "}
            {vcsProvider === "github" ? "GitHub" : "GitLab"} account.
          </Text>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: tokens.spacing[8],
          }}
        >
          <LinkButton
            to={oauthUrl}
            openInCurrentTab
            css={{
              "display": "flex",
              "justifyContent": "center",
              "span, button": { flex: 1 },
              "width": "fit-content",
            }}
            size="large"
            variant="primary"
            Icon={vcsProvider === "github" ? GitHub : GitLab}
            noWrapper
          >
            {vcsProvider === "github" ? "Authenticate with GitHub" : "Authenticate with GitLab"}
          </LinkButton>
          <Text color="secondary" size="small" css={{ textAlign: "center", maxWidth: 210 }} paragraph>
            By using Garden, you agree to our <Link to="https://garden.io/terms-of-service">Terms of Service</Link> and{" "}
            <Link to="https://garden.io/privacy">Privacy Policy</Link>.
          </Text>
        </div>
      </div>
    </div>
  )
}

export const PublicPage = ({
  children,
  navBackAction,
}: {
  children: React.ReactNode
  navBackAction: { text: string; url: string } | false
}) => {
  return (
    <ContentShell authState="success" isFreeTier={false}>
      <div css={{ backgroundColor: tokens.colors["element-100"], minHeight: "100vh" }}>
        <nav
          css={{
            height: tokens.spacing[80],
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            css={{
              maxWidth: "64rem",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                padding: `${tokens.spacing[8]} ${tokens.spacing[12]}`,
                height: tokens.spacing[40],
              }}
            >
              <PrimaryLogo title="Garden" css={{ width: 128, height: tokens.spacing[32] }} />
            </div>
            {navBackAction ? (
              <Link color="primary" to={navBackAction.url} openInCurrentTab decorate={false}>
                <IconText icon={<Icon Component={ChevronLeft} title={null} />}>{navBackAction.text}</IconText>
              </Link>
            ) : null}
          </div>
        </nav>
        <main
          css={{
            maxWidth: "64rem",
            width: "100%",
            padding: tokens.spacing[12],
            margin: "0 auto",
          }}
        >
          {children}
        </main>
      </div>
    </ContentShell>
  )
}

const AuthInfoBlock = ({
  icon,
  heading,
  text,
  styles,
}: {
  icon: IconComponent
  heading: string
  text: string
  styles?: CSSObject
}) => {
  return (
    <div css={{ display: "flex", alignItems: "center", gap: tokens.spacing[16], ...styles }}>
      <div
        css={{
          padding: tokens.spacing[8],
          backgroundColor: tokens.colors["pure-white"],
          border: `1px solid ${tokens.colors["pure-white"]}`,
          boxShadow: tokens.shadows["shadow-card"],
          borderRadius: tokens.borderRadii["radius-regular"],
          ...mqMobile({ alignSelf: "flex-start" }),
        }}
      >
        <Icon
          Component={icon}
          title="graph"
          duotone
          styles={{
            // using non-theme color here as this screen should always be light
            "--duotone-icon-outline": lightTheme.colors["anchor-primary"],
            "--duotone-icon-background": lightTheme.colors["accent"],
          }}
        />
      </div>
      <div>
        <Heading level={4} styles={{ color: tokens.colors["pure-white"] }}>
          {heading}
        </Heading>
        <Text paragraph styles={{ marginTop: tokens.spacing[4] }}>
          {text}
        </Text>
      </div>
    </div>
  )
}
